import React from "react";
import { Route, Redirect } from "react-router-dom";
import authService from "../services/authService";

const AdminProtectedRoute = ({
  path,
  component: Component,
  render,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const user = authService.getCurrentUser();
        const refreshToken = authService.getRefreshToken();

        if (!user) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        }

        if (refreshToken && Date.now() >= refreshToken.exp * 1000) {
          authService.logout();
          window.location = "/login";
        }

        if (!refreshToken) {
          authService.logout();
          window.location = "/login";
        }

        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default AdminProtectedRoute;
